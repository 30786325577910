<template>
  <div class="prompt-outer-container">
    <div class="prompt-container">
      <button class="prompt-close" v-on:click="$emit('close')"></button>
      <div class="prompt-container-badge report"></div>
      <div class="prompt">
        <div class="prompt-header">
          <h1>Report Content</h1>
        </div>

        <div class="prompt-body">
          <div v-if="showsConfirmationMessage">
            <div class="confirmation-message">
              <p>Your issue has been reported.</p>
              <p>
                <a href="#" class="action" v-on:click="$emit('close')">Close</a>
              </p>
            </div>
          </div>
          <div v-else class="form-container">
            <div class="form-container-element">
              <label>Issue</label>
              <select v-model="selectedIssue">
                <option v-for="issue in issues" v-bind:value="issue.value">
                  {{ issue.text }}
                </option>
              </select>
            </div>

            <div class="form-container-element">
              <label>Message</label>
              <textarea v-model="message"></textarea>
            </div>

            <div class="form-container-element">
              <button
                class="submit p-submit"
                v-on:click="submit"
                v-bind:disabled="isSubmitButtonDisabled"
              >
                {{ submitButtonTitle }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sessionId", "slug"],
  data() {
    return {
      loading: false,
      showsConfirmationMessage: false,
      selectedIssue: "Copyright infringement, illegal content",
      issues: [
        {
          text: "Copyright infringement, illegal content",
          value: "Copyright infringement, illegal content"
        },
        {
          text: "Suggestive, Unsettling, Contains Mature Themes",
          value: "Suggestive, Unsettling, Contains Mature Themes"
        },
        {
          text: "Nudity, Sexual Activity, Non-Consensual Sexualization",
          value: "Nudity, Sexual Activity, Non-Consensual Sexualization"
        },
        {
          text: "Spam, Fake News, Scams, Fraud",
          value: "Spam, Fake News, Scams, Fraud"
        },
        {
          text: "Discrimination, Hateful Ideology, Gore",
          value: "Discrimination, Hateful Ideology, Gore"
        },
        {
          text: "Harassment, Self-Harm, Personal Information",
          value: "Harassment, Self-Harm, Personal Information"
        },
        { text: "Other", value: "Other" }
      ],
      message: null
    };
  },
  computed: {
    isSubmitButtonDisabled: function() {
      return !this.containsMessage || this.loading;
    },
    containsMessage: function() {
      return this.message != null && this.message.length > 3;
    },
    submitButtonTitle: function() {
      return this.loading ? "Loading..." : "Submit";
    }
  },
  methods: {
    submit: function(event) {
      event.preventDefault();

      this.loading = true;
      const payload = {
        issue: this.selectedIssue,
        message: this.message
      };

      const requestOptions = {
        method: "POST",
        headers: { "X-DROPOVER-SESSION-P": "." },
        body: JSON.stringify(payload)
      };

      const session = this.sessionId ?? this.slug;

      fetch("https://a.dropoverapp.com/cloud/report/" + session, requestOptions)
        .then(r => {
          return r.json();
        })
        .then(json => {
          this.loading = false;
          this.showsConfirmationMessage = true;
        })
        .catch(error => {
          this.loading = false;
          this.showsConfirmationMessage = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.confirmation-message {
  display: block;
  text-align: center;

  h3 {
    font-size: 30px;
    font-weight: 700;
  }

  a {
    display: block;
    margin-top: 20px;
  }

  @media (prefers-color-scheme: dark) {
    h3 {
      color: white;
    }

    p {
      color: #eee;
    }
  }
}
</style>
